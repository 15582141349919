/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 * with the License. A copy of the License is located at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 * OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS'
export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED'
export const AUTH_LOGIN_CHALLENGE = 'AUTH_LOGIN_CHALLENGE'
export const AUTH_PASSWORD_RESET_REQUIRED = 'AUTH_PASSWORD_RESET_REQUIRED'

export const SERVER_ERROR = 'SERVER_ERROR'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const REQUEST_TIMEOUT = 'REQUEST_TIMEOUT'
